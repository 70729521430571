import React from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';

import $ from 'jquery';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Booking = (props) => {
  const { t } = props;

  const onSubmit = (e) => {
    e.preventDefault();
    let $this = $(e.target);

    const vorname = $('#bookingForm input[name=vorname]').val();
    const nachname = $('#bookingForm input[name=nachname]').val();
    const email = $('#bookingForm input[name=email]').val();
    const tel = $('#bookingForm input[name=tel]').val();
    const set = $('#bookingForm select[name=set]').val();
    const message = $('#bookingForm textarea[name=nachricht]').val();

    const MySwal = withReactContent(Swal);

    $.ajax({
      url: 'https://adenite.com/mail/contact_me.php',
      type: 'POST',
      data: {
        name: `${vorname} ${nachname}`,
        tel: tel,
        email: email,
        nachricht: message,
        set: set,
      },
      cache: false,
      success: function () {
        MySwal.fire({
          icon: 'success',
          title: t('booking.formular.alerts.success.title'),
          text: t('booking.formular.alerts.success.text'),
        });
      },
      error: function () {
        MySwal.fire({
          icon: 'error',
          title: t('booking.formular.alerts.error.title'),
          text: t('booking.formular.alerts.error.text'),
        });
      },
      complete: function () {
        setTimeout(function () {
          $this.prop('disabled', false); // Re-enable submit button when AJAX call is complete
        }, 1000);
      },
    });
  };

  return (
    <section id='booking' className='page-section'>
      <Container>
        <Row>
          <Col lg='12' className='text-center'>
            <h2 className='section-heading text-uppercase'>
              {t('booking.title')}
            </h2>
            <h3 className='section-subheading text-muted'>
              {t('booking.subtitle')}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <Form method='post' onSubmit={onSubmit} id='bookingForm'>
              <Row>
                <Col md='6'>
                  <Row className='mb-2'>
                    <Col lg='6'>
                      <Form.Group>
                        <Form.Label>
                          {t('booking.formular.vorname')} *
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder={`${t('booking.formular.vorname')} *`}
                          name='vorname'
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg='6'>
                      <Form.Group>
                        <Form.Label>
                          {t('booking.formular.nachname')} *
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder={`${t('booking.formular.nachname')} *`}
                          name='nachname'
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-2'>
                    <Col lg='12'>
                      <Form.Group>
                        <Form.Label>{t('booking.formular.email')} *</Form.Label>
                        <Form.Control
                          type='email'
                          placeholder={`${t('booking.formular.email')} *`}
                          name='email'
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-2'>
                    <Col lg='6'>
                      <Form.Group>
                        <Form.Label>
                          {t('booking.formular.telefon')} *
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder={`${t('booking.formular.telefon')} *`}
                          name='tel'
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg='6'>
                      <Form.Group>
                        <Form.Label>
                          {t('booking.formular.set.label')} *
                        </Form.Label>
                        <Form.Select name='set' required>
                          <option>
                            {t('booking.formular.set.auswaehlen')}
                          </option>
                          <option value='1'>
                            {t('booking.formular.set.option1')}
                          </option>
                          <option value='2'>
                            {t('booking.formular.set.option2')}
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg='6'>
                  <Form.Label>
                    {t('booking.formular.ihre_nachricht')} *
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    id='message'
                    placeholder={`${t('booking.formular.ihre_nachricht')} *`}
                    name='nachricht'
                    required
                  />
                </Col>
                <Col lg='12' className='text-center'>
                  <Button
                    variant='primary'
                    type='submit'
                    className='booking-button btn-xl text-uppercase'
                  >
                    {t('booking.formular.senden')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Booking;
