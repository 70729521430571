import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const ReferenceBox = (props) => {
  const { title, description, thumbnailLink, id, language } = props;

  return (
    <Col md='4' sm='6' className='portfolio-item'>
      <Link to={`/references/${language}/${id}`} className='portfolio-link'>
        <div className='portfolio-hover'>
          <div className='portfolio-hover-content'>
            <FontAwesomeIcon icon={faPlus} className='fa-3x' />
          </div>
        </div>
        <LazyLoadImage
          className='img-fluid'
          src={`https://adenite.com/img/blogs/${thumbnailLink}`}
          effect='blur'
        />
      </Link>
      <div className='portfolio-caption'>
        <div className='portfolio-caption-title'>
          <h4>{title}</h4>
        </div>
        <div className='portfolio-caption-description'>
          <p className='text-muted'>{description}</p>
        </div>
      </div>
    </Col>
  );
};

export default ReferenceBox;
