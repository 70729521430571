import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

const History = (props) => {
  const { t } = props;

  const timeline = [
    {
      title: t('timeline.entrys.1.title'),
      date: t('timeline.entrys.1.date'),
      peak: t('timeline.entrys.1.peak'),
      desc: t('timeline.entrys.1.description'),
    },
    {
      title: t('timeline.entrys.2.title'),
      date: t('timeline.entrys.2.date'),
      peak: t('timeline.entrys.2.peak'),
      desc: t('timeline.entrys.2.description'),
    },
    {
      title: t('timeline.entrys.3.title'),
      date: t('timeline.entrys.3.date'),
      peak: t('timeline.entrys.3.peak'),
      desc: t('timeline.entrys.3.description'),
    },
    {
      title: t('timeline.entrys.4.title'),
      date: t('timeline.entrys.4.date'),
      peak: t('timeline.entrys.4.peak'),
      desc: t('timeline.entrys.4.description'),
    },
    {
      title: t('timeline.entrys.5.title'),
      date: t('timeline.entrys.5.date'),
      peak: t('timeline.entrys.5.peak'),
      desc: t('timeline.entrys.5.description'),
    },
    {
      title: t('timeline.entrys.6.title'),
      date: t('timeline.entrys.6.date'),
      peak: t('timeline.entrys.6.peak'),
      desc: t('timeline.entrys.6.description'),
    },
    {
      title: t('timeline.entrys.7.title'),
      date: t('timeline.entrys.7.date'),
      peak: t('timeline.entrys.7.peak'),
      desc: t('timeline.entrys.7.description'),
    },
    {
      title: t('timeline.entrys.8.title'),
      date: t('timeline.entrys.8.date'),
      peak: t('timeline.entrys.8.peak'),
      desc: t('timeline.entrys.8.description'),
    },
    {
      title: t('timeline.entrys.9.title'),
      date: t('timeline.entrys.9.date'),
      peak: t('timeline.entrys.9.peak'),
      desc: t('timeline.entrys.9.description'),
    },
    {
      title: t('timeline.entrys.10.title'),
      date: t('timeline.entrys.10.date'),
      peak: t('timeline.entrys.10.peak'),
      desc: t('timeline.entrys.10.description'),
    },
    {
      date: '',
      title: '',
      peak: '...',
      desc: '',
    },
  ];

  return (
    <section id='history' className='page-section'>
      <Container>
        <Row>
          <Col lg='12' className='text-center'>
            <h2 className='section-heading text-uppercase'>
              {t('timeline.title')}
            </h2>
            <h3
              className='section-subheading text-muted'
              dangerouslySetInnerHTML={{ __html: t('timeline.subtitle') }}
            ></h3>
          </Col>
        </Row>
        <Row>
          <Col lg='12'>
            <ul className='timeline'>
              {timeline.map((item, index) => (
                <li
                  key={index}
                  className={index % 2 === 0 ? '' : 'timeline-inverted'}
                >
                  <div className='timeline-image'>
                    <h4>{item.peak}</h4>
                  </div>
                  {item.title !== '' ? (
                    <div className='timeline-panel'>
                      <div className='timeline-heading'>
                        <h4>{item.title}</h4>
                        <h5>{item.date}</h5>
                      </div>
                      <div className='timeline-body'>
                        <p
                          className='text-muted'
                          dangerouslySetInnerHTML={{ __html: item.desc }}
                        ></p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default History;
