import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Calendar from './Calendar';
import { useParams } from 'react-router';

const VenueModal = (props) => {
  const { venueid } = props;
  const { language } = useParams();
  const [isLoaded, setisLoaded] = useState(false);
  const [data, setdata] = useState({
    data: [{}],
  });
  useEffect(() => {
    fetch(
      `https://adenite.com/auftritte/api/api.php?METHOD=SELECT&only_public=1&only_future=1&id=${venueid}&language=${language}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setdata(result);
          setisLoaded(true);
        },
        (error) => {}
      );
  }, [venueid, language]);

  return (
    <div className='custom-modal-wrapper'>
      <div className='custom-modal'>
        <Link to='/' className='custom-modal-close'>
          <FontAwesomeIcon icon={faTimes} className='fa-2x' />
        </Link>
        <div className='custom-modal-overflow-scroll'>
          <div className='custom-modal-content'>
            <Row className='custom-modal-title'>
              <h2>{isLoaded ? data.data[0].titel : ''}</h2>
              <p className='text-muted'>
                {isLoaded ? data.data[0].datum.time : ''}
              </p>
            </Row>
            <Row>
              <Col md='2' lg='2'>
                {isLoaded ? (
                  <Calendar
                    year={data.data[0].datum.year.substring(
                      data.data[0].datum.year.length - 2
                    )}
                    month={data.data[0].datum.month}
                    month_full={data.data[0].datum.month_full}
                    day={data.data[0].datum.day}
                  />
                ) : (
                  ''
                )}
              </Col>
              <Col md='10' lg='10'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: isLoaded ? data.data[0].beschreibung : '',
                  }}
                ></div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenueModal;
