import {
  faCircle,
  faGuitar,
  faLaughBeam,
  faMusic,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

const Service = (props) => {
  const { t } = props;

  return (
    <section id='service' className='page-section'>
      <Container>
        <Row>
          <Col lg='12' className='text-center'>
            <h2 className='section-heading text-uppercase'>
              {t('service.title')}
            </h2>
            <h3 className='section-subheading text-muted'>
              {t('service.subtitle')}
            </h3>
          </Col>
        </Row>
        <Row className='text-center'>
          <Col md='4'>
            <span className='fa-stack fa-4x'>
              <FontAwesomeIcon
                icon={faCircle}
                className='fa-stack-2x text-primary'
              />
              <FontAwesomeIcon
                icon={faMusic}
                className='fa-stack-1x fa-inverse'
              />
            </span>
            <h4 className='service-heading'>{t('service.vielfalt.title')}</h4>
            <p className='text-muted'>{t('service.vielfalt.description')}</p>
          </Col>
          <Col md='4'>
            <span className='fa-stack fa-4x'>
              <FontAwesomeIcon
                icon={faCircle}
                className='fa-stack-2x text-primary'
              />
              <FontAwesomeIcon
                icon={faGuitar}
                className='fa-stack-1x fa-inverse'
              />
            </span>
            <h4 className='service-heading'>{t('service.music.title')}</h4>
            <p className='text-muted'>{t('service.music.description')}</p>
          </Col>
          <Col md='4'>
            <span className='fa-stack fa-4x'>
              <FontAwesomeIcon
                icon={faCircle}
                className='fa-stack-2x text-primary'
              />
              <FontAwesomeIcon
                icon={faLaughBeam}
                className='fa-stack-1x fa-inverse'
              />
            </span>
            <h4 className='service-heading'>
              {t('service.unterhaltung.title')}
            </h4>
            <p className='text-muted'>
              {t('service.unterhaltung.description')}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Service;
