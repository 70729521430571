import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
const ReferenceModal = (props) => {
  const { referenceId } = props;
  const { language } = useParams();
  const [data, setdata] = useState({});
  const [loaded, setloaded] = useState(false);

  useEffect(() => {
    fetch(`https://adenite.com/api/blog/${referenceId}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setdata(result.data[0]);
          setloaded(true);
        },
        (error) => {}
      );
  }, [referenceId, language]);

  return (
    <div className='custom-modal-wrapper'>
      <div className='custom-modal'>
        <Link to='/' className='custom-modal-close'>
          <FontAwesomeIcon icon={faTimes} className='fa-2x' />
        </Link>
        <div className='custom-modal-overflow-scroll'>
          {loaded === true ? (
            <>
              <div className='custom-modal-content'>
                <Row className='mobile-custom-modal-title'>
                  <h2>{data.title[language]}</h2>
                  <p className='text-muted'>{data.subtitle[language]}</p>
                </Row>
                <Row>
                  <Col md='12' lg='6'>
                    {data.mediatype[language] === 'img' ? (
                      <div className='reference-img'>
                        {data.media[language] !== '' ? (
                          <img
                            src={`https://adenite.com/img/blogs/${data.media[language]}`}
                            alt=''
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      <div className='embed-responsive embed-responsive-16by9'>
                        <iframe
                          src={`https://www.youtube.com/embed/${data.media[language]}?autoplay=0&fs=1&iv_load_policy=3&showinfo=1`}
                          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                          className='d-block mx-auto'
                          title={data.title[language]}
                        ></iframe>
                      </div>
                    )}
                  </Col>
                  <Col md='12' lg='6'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.description[language],
                      }}
                    ></div>
                  </Col>
                </Row>
              </div>
              <div className='custom-modal-date text-muted'>
                {data.monthyear[language]}
              </div>
            </>
          ) : (
            'loading'
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferenceModal;
