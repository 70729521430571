import React, { useEffect } from 'react';

import Header from '../components/home/Header';
import Service from '../components/home/Service';
import References from '../components/home/References';
import Portfolio from '../components/home/Portfolio';
import History from '../components/home/History';
import Venues from '../components/home/Venues';
import Booking from '../components/home/Booking';
import { useLocation, useParams } from 'react-router';
import ReferenceModal from '../components/home/references/ReferenceModal';
import VenueModal from '../components/home/venues/VenueModal';
import Notification from '../components/util/Notification';
import { useTranslation } from 'react-i18next';
import Promo from '../components/home/Promo';

/**
 * Seite "Home"
 */
const Home = ({ language }) => {
  const { t } = useTranslation('common');

  const { reference, venue } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    if (reference || venue) {
      document.querySelector('html').classList.add('custom-modal-open');
    } else {
      document.querySelector('html').classList.remove('custom-modal-open');
    }
  }, [pathname, reference, venue]);

  const dateNow = new Date();
  dateNow.setHours(0, 0, 0, 0);
  const dateRelease = new Date(2021, 10 - 1, 10);

  const subtitel =
    dateNow >= dateRelease
      ? t('notification.jetzt_erhaeltlich')
      : 'Erhältlich ab 10.10.21';

  return (
    <>
      <Notification
        subtitel={subtitel}
        displayUntil={new Date(2021, 10 - 1, 20)}
      >
        {t('do_it_without_you')}
      </Notification>
      {reference ? <ReferenceModal referenceId={reference} /> : ''}
      {venue ? <VenueModal language={language} venueid={venue} /> : ''}
      <Header leadin={t('header.leadin')} title={t('header.title')} t={t} />
      <Promo />
      <Service t={t} />
      <References language={language} t={t} />
      <History t={t} />
      <Portfolio t={t} />
      <Venues language={language} t={t} />
      <Booking t={t} />
    </>
  );
};

export default Home;
