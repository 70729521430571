import {
  faFacebook,
  faInstagram,
  faSpotify,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation('common');

  return (
    <footer className='footer'>
      <Container>
        <Row className='align-items-center'>
          <Col md='4'>
            <span className='copyright'>
              Copyright © Adenite {new Date().getFullYear()}
            </span>
          </Col>
          <Col md='4'>
            <ul className='list-inline social-buttons'>
              <li className='list-inline-item'>
                <a href='https://instagram.com/adenite.official'>
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li className='list-inline-item'>
                <a href='https://facebook.com/adenite.official'>
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li>
              <li className='list-inline-item'>
                <a href='https://youtube.com/channel/UCbC7F_uylk8Ifq4_e-GUF1Q'>
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </li>
              <li className='list-inline-item'>
                <a href='https://open.spotify.com/artist/5vJba8KAlEcxxIfZXjWWhc?si=0rVFGEAZR-2xcVI3eSa7Tw&amp;dl_branch=1'>
                  <FontAwesomeIcon icon={faSpotify} />
                </a>
              </li>
            </ul>
          </Col>
          <Col md='4' className='quicklinks'>
            <Link to='/impressum'>{t('footer.impressum')}</Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
