import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Portfolio = (props) => {
  const { t } = props;
  const teamMembers = [
    {
      name: t('portfolio.members.steven.name'),
      role: t('portfolio.members.steven.role'),
      image: 'https://adenite.com/img/team/steven.jpg',
      insta: 'https://www.instagram.com/steven.cherry.official/',
    },
    {
      name: t('portfolio.members.tobias.name'),
      role: t('portfolio.members.tobias.role'),
      image: 'https://adenite.com/img/team/tobias.jpg',
      insta: 'https://www.instagram.com/tobias_roos_music/',
    },
    {
      name: t('portfolio.members.nico.name'),
      role: t('portfolio.members.nico.role'),
      image: 'https://adenite.com/img/team/nico.jpg',
      insta: 'https://www.instagram.com/w._nico_/',
    },
    {
      name: t('portfolio.members.pascal.name'),
      role: t('portfolio.members.pascal.role'),
      image: 'https://adenite.com/img/team/pascal.jpg',
      insta: 'https://www.instagram.com/pas23wol/',
    },
  ];
  return (
    <section id='portfolio' className='page-section'>
      <Container>
        <Row>
          <Col lg='12' className='text-center'>
            <h2 className='section-heading text-uppercase'>
              {t('portfolio.title')}
            </h2>
            <h3
              className='section-subheading text-muted'
              dangerouslySetInnerHTML={{ __html: t('portfolio.subtitle') }}
            ></h3>
          </Col>
        </Row>
        <Row>
          {teamMembers.map((item, index) => (
            <Col lg='3' md='6' sm='12' key={index}>
              <div className='team-member'>
                <LazyLoadImage
                  className='mx-auto rounded-circle'
                  src={item.image}
                  alt={item.name}
                />
                <h4>{item.name}</h4>
                <p className='text-muted'>{item.role}</p>
                <ul className='list-inline social-buttons'>
                  <li className='list-inline-item'>
                    <a href={item.insta} target='_blank' rel='noreferrer'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Row lg='8' className='mx-auto text-center'>
            <p className='large text-muted'>{t('portfolio.instagram')}</p>
          </Row>
        </Row>
      </Container>
    </section>
  );
};

export default Portfolio;
