import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ReferenceBox from './references/ReferenceBox';

const Refernces = (props) => {
  const [data, setdata] = useState([]);
  const [error, setError] = useState(false);

  const { t, language } = props;
  useEffect(() => {
    fetch(`https://adenite.com/api/blog/`)
      .then((res) => res.json())
      .then(
        (result) => {
          setdata(result.data);
        },
        (error) => {
          setError(true);
        }
      );
  }, [language]);

  const [database, setdatabase] = useState('');
  useEffect(() => {
    fetch('https://adenite.com/api/database/')
      .then((res) => res.json())
      .then((result) => {
        setdatabase(result.dbname);
      });
  }, []);

  return (
    <section id='references' className='page-section'>
      <Container>
        <Row>
          <Col lg='12' className='text-center'>
            <h2 className='section-heading text-uppercase'>
              {t('references.title')}
            </h2>
            <h3 className='section-subheading text-muted'>
              {t('references.subtitle')}
            </h3>
          </Col>
        </Row>
        <Row>
          {!error && database !== 'adenitec_api_dev' && data ? (
            <>
              {data.map((key, index) => (
                <ReferenceBox
                  title={key.title[language]}
                  description={key.subtitle[language]}
                  thumbnailLink={key.thumbnail[language]}
                  id={key.id}
                  key={index}
                  language={language}
                />
              ))}
            </>
          ) : (
            <Col className='text-center'>{t('technical_error')}</Col>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default Refernces;
