import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Calendar from './venues/Calendar';

const Venues = (props) => {
  const { t, language } = props;
  const [data, setdata] = useState({ data: [] });
  useEffect(() => {
    fetch(
      `https://adenite.com/auftritte/api/api.php?METHOD=SELECT&only_public=1&only_future=1&language=${language}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setdata(result);
        },
        (error) => {}
      );
  }, [language]);

  return (
    <section id='venues' className='page-section'>
      <Container>
        <Row>
          <Col lg='12' className='text-center'>
            <h2 className='section-heading text-uppercase'>
              {t('auftritte.title')}
            </h2>
            <h3
              className='section-subheading text-muted'
              dangerouslySetInnerHTML={{ __html: t('auftritte.subtitle') }}
            ></h3>
          </Col>
        </Row>
        {data.data.length >= 1 ? (
          <>
            {data.data.map((key, index) => (
              <Row key={index}>
                <Col lg={{ span: 6, offset: 3 }}>
                  <div className='venue-item'>
                    <Calendar
                      year={key.datum.year.substring(key.datum.year.length - 2)}
                      month={key.datum.month}
                      month_full={key.datum.month_full}
                      day={key.datum.day}
                    />
                    <div className='venue-text'>
                      <h4>
                        {key.titel}
                        {key.display_time ? ` - ${key.datum.time}` : ''}
                      </h4>
                      {key.beschreibung !== '' ? (
                        <Link to={`/venues/${language}/${key.id}`}>
                          {t('auftritte.mehr_infos')}
                        </Link>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <Row>
            <Col
              lg={{ span: 6, offset: 3 }}
              className='text-center  text-muted'
            >
              {t('auftritte.keine_auftritte')}
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default Venues;
