import React, { useCallback, useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DarkMode from './util/DarkMode';

const Navigation = (props) => {
  const { t } = useTranslation('common');
  const { language, onLanguageChange } = props;

  const [shrinked, setShrinked] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleLanguageClick = useCallback(
    (event) => {
      let allowed_language = ['de', 'en'];
      let language = event.target.innerHTML;
      if (allowed_language.includes(language)) {
        onLanguageChange(language);
      }
    },
    [onLanguageChange]
  );

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollTop = document.scrollingElement.scrollTop;
    setShrinked(scrollTop > 100);
  };

  const handleNavLinkClick = (e) => {
    const target_href = e.target.href;
    const matches_id = /#([a-zA-Z0-9_-]*)$/.exec(target_href) ?? ['', ''];
    const target_id = matches_id[1];
    if (window.innerWidth < 992) {
      setExpanded(false);
    }

    setTimeout(() => {
      const target = document.getElementById(target_id);
      if (target !== null)
        scrollTo(document.scrollingElement, target.offsetTop - 80, 400);
    }, 100);
  };

  const scrollTo = (element, to, duration) => {
    if (duration <= 0) {
      element.scrollTop = to;
      return;
    }
    var difference = to - element.scrollTop;
    var perTick = (difference / duration) * 10;

    setTimeout(function () {
      element.scrollTop = element.scrollTop + perTick;
      if (element.scrollTop === to) return;
      scrollTo(element, to, duration - 10);
    }, 10);
  };

  return (
    <Navbar
      collapseOnSelect
      bg='dark'
      expand='lg'
      fixed='top'
      variant='dark'
      id='mainNav'
      className={`${shrinked ? 'navbar-shrink' : ''} ${
        expanded ? 'mobile-collapsed' : ''
      }`}
      onToggle={() => {
        setExpanded(!expanded);
      }}
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand onClick={handleNavLinkClick} href='/#page-top'>
          <img
            src='https://adenite.com/img/adenite_logo_white.png'
            alt={t('brand-name')}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />

        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mx-auto language'>
            <div>
              <span
                onClick={handleLanguageClick}
                className={`${language === 'de' ? 'language-active' : ''}`}
              >
                de
              </span>
              /
              <span
                onClick={handleLanguageClick}
                className={language === 'en' ? 'language-active' : ''}
              >
                en
              </span>
            </div>
          </Nav>
          <Nav className='mx-auto'>
            <Link
              onClick={handleNavLinkClick}
              className='nav-link'
              to='/#service'
            >
              {t('navigation.service')}
            </Link>
            <Link
              onClick={handleNavLinkClick}
              className='nav-link'
              to='/#references'
            >
              {t('navigation.auftritte')}
            </Link>
            <Link
              onClick={handleNavLinkClick}
              className='nav-link'
              to='/#history'
            >
              {t('navigation.ueber_uns')}
            </Link>
            <Link
              onClick={handleNavLinkClick}
              className='nav-link'
              to='/#portfolio'
            >
              {t('navigation.band')}
            </Link>
            <Link
              onClick={handleNavLinkClick}
              className='nav-link'
              to='/#venues'
            >
              {t('navigation.naechste_auftritte')}
            </Link>
            <Link
              onClick={handleNavLinkClick}
              className='nav-link'
              to='/#booking'
            >
              {t('navigation.book')}
            </Link>
            <Nav.Link className='d-none' href='#'>
              {t('navigation.merch')}
            </Nav.Link>
            <Nav.Link className='d-md-block d-lg-none'>
              <DarkMode className='show-mobile' />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <DarkMode />
    </Navbar>
  );
};

export default Navigation;
