import React from 'react';

const Calendar = (props) => {
  const { year, month, month_full, day } = props;

  return (
    <div className='calendar-wrapper'>
      <div className='calendar'>
        <div className='top'>
          <div className='month month_full'>{month_full}</div>
          <div className='month'>{month}</div>
          <div className='year'>{year}</div>
        </div>
        <div className='bottom'>
          <div className='day'>{day}</div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
