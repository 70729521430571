import React, { Suspense, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Navigation from './components/Navigation';

// Import Hooks
// eslint-disable-next-line
import ScrollToTop from './hooks/ScrollToTop';

// Import Pages
import Home from './pages/Home';
import Impressum from './pages/Impressum';
import NoMatch from './pages/NoMatch';

import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

function App() {
  const [cookies, setCookie] = useCookies(['language']);
  const [language, setlanguage] = useState(cookies.language ?? 'de');
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common');
  const updateLanguage = (lang) => {
    setCookie('language', lang);
    setlanguage(lang);
    i18n.changeLanguage(lang);
  };
  if (getLanguage() !== language) {
    updateLanguage(getLanguage());
  }
  function getLanguage() {
    const user_language = navigator.language;
    if (!user_language.includes('de')) return 'en';
    if (cookies.language) return cookies.language;
    return 'de';
  }
  return (
    <Suspense fallback='loading'>
      <Router>
        {/* Wenn die Seite gewechselt wird, scrollt dieser Component die Seite nach oben */}
        {/* <ScrollToTop /> */}

        <div id='main'>
          <Navigation language={language} onLanguageChange={updateLanguage} />
          <Switch>
            {/* Home */}
            <Route
              exact
              path={[
                '/',
                '/references/:language/:reference',
                '/venues/:language/:venue',
              ]}
            >
              <Home language={language} />
            </Route>

            {/* Impressum */}
            <Route path='/impressum' component={Impressum} />

            {/* 404 */}
            <Route path='*' component={NoMatch} status={404} />
          </Switch>
          <Footer />
        </div>
      </Router>
    </Suspense>
  );
}

export default App;
