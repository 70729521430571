import React from 'react';
import { Container } from 'react-bootstrap';

import Header from '../components/home/Header';
import ScrollToTop from '../hooks/ScrollToTop';
import { useTranslation } from 'react-i18next';
/**
 * Seite "Home"
 */
const Impressum = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <ScrollToTop />
      <Header leadin='' title={t('impressum.title')} error />
      <section className='page-section'>
        <Container>
          <h1>{t('impressum.title')}</h1>
          <h4>{t('impressum.kontakt_adresse')}</h4>
          <p>
            Adenite
            <br />
            St. Gallerstrasse 36
            <br />
            9230 Flawil
            <br />
            {t('impressum.schweiz')}
            <br />
          </p>

          <h4>{t('impressum.email')}</h4>
          <p>info@adenite.com</p>

          <h4>{t('impressum.vertretung')}</h4>
          <p>{t('impressum.vertretung_person')}</p>

          <h4>{t('impressum.haftungsausschluss')}</h4>

          <p
            dangerouslySetInnerHTML={{
              __html: t('impressum.haftungsausschluss_text'),
            }}
          />

          <h4>{t('impressum.haftungsausschluss_links')}</h4>
          <p
            dangerouslySetInnerHTML={{
              __html: t('impressum.haftungsausschluss_links_text'),
            }}
          />

          <h4>{t('impressum.urheberrechte')}</h4>
          <p
            dangerouslySetInnerHTML={{
              __html: t('impressum.urheberrechte_text'),
            }}
          />
          <p
            className='text-muted'
            dangerouslySetInnerHTML={{ __html: t('impressum.quelle') }}
          ></p>
        </Container>
      </section>
    </>
  );
};

export default Impressum;
