import React from 'react';
import { Container } from 'react-bootstrap';

const Header = (props) => {
  const { leadin, title, error, t } = props;
  return (
    <header id='page-top'>
      <Container>
        <div className='intro-text'>
          <div className='intro-lead-in'>{leadin}</div>
          <div className='intro-heading text-uppercase'>{title}</div>
          {!error ? (
            <a
              href='#history'
              className='btn btn-primary btn-xl text-uppercase'
            >
              {t('header.ueber_uns')}
            </a>
          ) : (
            ''
          )}
        </div>
      </Container>
    </header>
  );
};

export default Header;
