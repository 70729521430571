import promocard from '../../assets/styles/imgs/somewhere_deep_inside_promo_card.png';
import { Container, Row } from 'react-bootstrap';
const Promo = () => {
  return (
    <section id='portfolio' className='page-section'>
      <Container>
        <Row>
          <a
            href='https://promocards.byspotify.com/share/033e66b8b73658c6e0574719e788f75fc55b7cd1'
            className='promocard'
          >
            <img src={promocard} alt='somewhere-deep-inside' />
          </a>
        </Row>
      </Container>
    </section>
  );
};

export default Promo;
