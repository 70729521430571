import {
  faApple,
  faDeezer,
  faItunes,
  faSpotify,
} from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

const Notification = (params) => {
  const { subtitel, displayUntil } = params;
  const titel = params.children;

  const [cookies, setCookie] = useCookies(['notification_diwu']);

  const dateNow = new Date();
  dateNow.setHours(0, 0, 0, 0);

  const displayNotificationInit =
    cookies.notification_diwu !== 'true' && displayUntil >= dateNow;
  const [displayNotification, setdisplayNotification] = useState(
    displayNotificationInit
  );

  if (displayNotification)
    document.querySelector('body').classList.add('notification-no-scroll');

  const onCloseIcon = () => {
    setdisplayNotification(false);
    setCookie('notification_diwu', 'true');
    document.querySelector('body').classList.remove('notification-no-scroll');
  };
  return (
    <>
      {displayNotification ? (
        <div className='notification-wrapper'>
          <div className='notification'>
            <div className='notification-content'>
              <div className='notification-close' onClick={onCloseIcon}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div className='notification-text-top'>
                <div className='notification-title'>{titel}</div>
                <div className='notification-subtitle'>{subtitel}</div>
              </div>

              <div className='notification-text-bottom streaming-brands'>
                <a
                  href='https://open.spotify.com/artist/5vJba8KAlEcxxIfZXjWWhc?si=G8mm_fgyQ-uw6LzUYqZvhQ&dl_branch=1'
                  className='icon'
                >
                  <FontAwesomeIcon icon={faSpotify} />
                </a>
                <a
                  href='https://music.apple.com/ch/artist/adenite/1576866930'
                  className='icon'
                >
                  <FontAwesomeIcon icon={faApple} />
                </a>
                <a
                  href='https://www.deezer.com/de/artist/139559822'
                  className='icon'
                >
                  <FontAwesomeIcon icon={faDeezer} />
                </a>
                <a
                  href='https://music.apple.com/ch/artist/adenite/1576866930'
                  className='icon'
                >
                  <FontAwesomeIcon icon={faItunes} />
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Notification;
