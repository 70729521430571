import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const DarkMode = (params) => {
  const { className } = params;
  const [DarkmodeIcon, setDarkmodeIcon] = useState(faSun);
  function toggle_darkmode(e) {
    e.preventDefault();
    const darkmode_class = 'darkmode';
    if (document.body.classList.contains(darkmode_class)) {
      document.body.classList.remove(darkmode_class);
      setDarkmodeIcon(faSun);
      return;
    }
    setDarkmodeIcon(faMoon);
    document.body.classList.add(darkmode_class);
  }

  return (
    <div
      id='toggle-darkmode'
      onClick={toggle_darkmode}
      className={className + ' d-none'}
    >
      <FontAwesomeIcon icon={DarkmodeIcon} />
    </div>
  );
};

export default DarkMode;
