import React from 'react';
import Header from '../components/home/Header';

/**
 * Seite "404"
 */
const NoMatch = () => {
  return (
    <Header
      leadin='Die Seite, welche du aufgerufen hast, ist nicht verfügbar'
      title='Ups... Verspielt'
      error
    />
  );
};

export default NoMatch;
