// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Import Style
import './assets/styles/style.scss';
import common_de from './translations/de/common.json';
import common_en from './translations/en/common.json';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import { createRoot } from 'react-dom/client';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  // lng: getCookie('language'), // language to use
  lng: getLanguage(), // language to use
  resources: {
    de: {
      common: common_de,
    },
    en: {
      common: common_en,
    },
  },
});

function getLanguage() {
  const user_language = navigator.language;
  if (!user_language.includes('de')) return 'en';
  if (getCookie('language') !== undefined) return getCookie('language');
  return 'de';
}

function getCookie(cName) {
  const name = cName + '=';
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
